// import { BoardSections, Status, CandidatesType
import {
  CandidatesType,
  BoardSections,
  BOARD_SECTIONS,
} from "dummy-json/candidates";
import { getTasksByStatus } from "./tasks";

export const initializeBoard = (tasks: CandidatesType[]) => {
  const boardSections: BoardSections = {};

  Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
    boardSections[boardSectionKey] = getTasksByStatus(tasks, boardSectionKey);
  });

  return boardSections;
};

export const findBoardSectionContainer = (
  boardSections: BoardSections,
  id: string
) => {
  if (id in boardSections) {
    return id;
  }

  const container = Object.keys(boardSections).find((key) =>
    boardSections[key].find((item) => item.id === id)
  );
  return container;
};
