import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import MenuOpenButton from "components/button/MenuOpenButton";
import ModalOpenButton from "components/button/ModalOpenButton";
import CardContainer from "components/cards/CardContainer";
import ModalContainer from "components/modal/ModalContainer";
import { clientList } from "dummy-json/client";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateClients from "./CreateClients";
import HeaderGrid from "components/cards/card-grid/HeaderGrid";

const Index = () => {
  const [modalToggle, setModalToggle] = useState(false);
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <CardContainer>
        <HeaderGrid>
          <div>
            <ModalOpenButton
              onClick={() => setModalToggle(true)}
              label={"Create Client"}
              iconName="plus-square"
            />
          </div>
          <div className="d-flex gap-2">
            <MenuOpenButton label="Filter" iconName="setting-4" />
            <MenuOpenButton label="More" iconName="dots-horizontal" />
          </div>
        </HeaderGrid>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Client Name</TableHeadCell>
              <TableHeadCell>Job Post</TableHeadCell>
              <TableHeadCell>Hired</TableHeadCell>
              <TableHeadCell>Industry</TableHeadCell>
              <TableHeadCell>Location</TableHeadCell>
              <TableHeadCell>Contact Person</TableHeadCell>
              <TableHeadCell>Contact Number</TableHeadCell>
            </TableHeadRow>
          </TableHead>

          <TableBody>
            {clientList.map((listItem) => {
              return (
                <TableBodyRow
                  onClick={() => {
                    navigate(`/client/${listItem.guid}?tab=jobs`);
                  }}
                  className="cursor-pointer"
                  key={listItem.guid}
                >
                  <TableBodyCell>{listItem.clientName}</TableBodyCell>
                  <TableBodyCell>{listItem.numberOfJobPost}</TableBodyCell>
                  <TableBodyCell>{listItem.numberOfHired}</TableBodyCell>
                  <TableBodyCell>{listItem.Industry}</TableBodyCell>
                  <TableBodyCell>{listItem.Location}</TableBodyCell>
                  <TableBodyCell>{listItem.contactPerson}</TableBodyCell>
                  <TableBodyCell>{listItem.contactNumber}</TableBodyCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContainer>
      <ModalContainer
        modalToggle={modalToggle}
        setModalToggle={setModalToggle}
        title="Create Client"
      >
        {modalToggle && <CreateClients />}
      </ModalContainer>
    </React.Fragment>
  );
};

export default Index;
