import { FC } from "react";
import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import { useTranslation } from "react-i18next";
import CardContainer from "components/cards/CardContainer";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from "components/Table";
import TableBodyRow from "components/Table/TableBodyRow";
import clsx from "clsx";
import FilledButtons from "components/button/FilledButtons";
import OutlineButton from "components/button/OutlineButton";
import CancelOutlineButton from "components/button/CancelOutlineButton";
type AssignedBy = {
  imageUrl?: string;
  guid: string;
  name: string;
  initials?: string;
  state?: string;
};

type Employed = {
  outOfTotal: number;
  total: string;
};

type AppliedCandidate = {
  guid: string;
  jobRole: string;
  company: string;
  numberOfApplication: string;
  assignedBy: AssignedBy[];
  employed: Employed;
};
const appliedCandidateJobWise: AppliedCandidate[] = [
  {
    guid: "kcmccdc1",
    jobRole: "Java Developer",
    company: "Niyantras",
    numberOfApplication: "40",
    assignedBy: [
      { guid: "vdfdfd", name: "Alan Warden", initials: "A", state: "warning" },
    ],
    employed: {
      outOfTotal: 8,
      total: "10",
    },
  },
  {
    guid: "kcmccdc2",
    jobRole: "Python Developer",
    company: "Niyantras",
    numberOfApplication: "40",
    assignedBy: [
      {
        guid: "eiiexdfdfdi",
        name: "Alan Warden",
        initials: "A",
        state: "warning",
      },
      {
        guid: "eiiei5",
        name: "Michael Eberon",
        imageUrl:
          "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
      },
      {
        guid: "eiiei3",
        name: "Susan Redwood",
        initials: "S",
        state: "primary",
      },
      {
        guid: "eiivfvei",
        name: "Melody Macy",
        imageUrl:
          "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
      },
      {
        guid: "eiivfdddvei",
        name: "Perry Matthew",
        initials: "P",
        state: "danger",
      },
      {
        guid: "vfvfvfefd",
        name: "Barry Walter",
        imageUrl: "https://unavatar.io/github/nolannordlund",
      },
    ],
    employed: {
      outOfTotal: 8,
      total: "10",
    },
  },
];
const topCandidates: any[] = [
  {
    guid: "t1",
    candidateName: "Alex John",
    jobRole: "Python Develop",
    pastExperience: "Microsoft",
    imageUrl:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
  {
    guid: "t2",
    candidateName: "Alex John",
    jobRole: "Java Developer",
    pastExperience: "Google",
    imageUrl:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
  {
    guid: "t3",
    candidateName: "Alex John",
    jobRole: "UI/UX",
    pastExperience: "L&T",
    imageUrl:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
];
const Dashboard: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective label={t("DASHBOARD")} />
      </Breadcrumbs>
      {/* Here we have not yet decided which details to show for that the details are currently hard-coded*/}
      <div className="row">
        <div className="col-8">
          <CardContainer>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <TableHeadCell>Job Role</TableHeadCell>
                  <TableHeadCell>Company</TableHeadCell>
                  <TableHeadCell>Application</TableHeadCell>
                  <TableHeadCell>Assigned By</TableHeadCell>
                  <TableHeadCell>Employed</TableHeadCell>
                </TableHeadRow>
              </TableHead>

              <TableBody>
                {appliedCandidateJobWise.map((listItem) => {
                  let percentage =
                    (listItem.employed.outOfTotal /
                      parseInt(listItem.employed.total)) *
                    100;

                  return (
                    <TableBodyRow key={listItem.guid}>
                      <TableBodyCell>{listItem.jobRole}</TableBodyCell>
                      <TableBodyCell>{listItem.company}</TableBodyCell>
                      <TableBodyCell>
                        {listItem.numberOfApplication}
                      </TableBodyCell>
                      <TableBodyCell>
                        <div className="symbol-group symbol-hover flex-nowrap">
                          {listItem.assignedBy.map((item) => (
                            <div
                              className="symbol symbol-25px symbol-circle"
                              data-bs-toggle="tooltip"
                              title={item.guid}
                              key={`cw7-item-${item.guid}`}
                            >
                              {item.imageUrl && (
                                <img alt="Pic" src={item.imageUrl} />
                              )}
                              {item.state && item.initials && (
                                <span
                                  className={clsx(
                                    "symbol-label fw-bold",
                                    "bg-" + item.state,
                                    "text-inverse-" + item.state
                                  )}
                                >
                                  {item.initials}
                                </span>
                              )}
                            </div>
                          ))}

                          <span className="symbol symbol-25px symbol-circle">
                            <span
                              className={clsx(
                                "symbol-label fs-9 fw-bold",
                                "bg-dark",
                                "text-light"
                              )}
                            >
                              +42
                            </span>
                          </span>
                        </div>
                      </TableBodyCell>
                      <TableBodyCell>
                        <div className="d-flex flex-column w-100 me-2">
                          <div className="d-flex flex-stack mb-2">
                            <span className="text-muted me-2 fs-7 fw-semibold">
                              50%
                            </span>
                          </div>
                          <div className="progress h-6px w-100">
                            <div
                              className="progress-bar bg-primary"
                              style={{
                                width: percentage + "%",
                              }}
                            ></div>
                          </div>
                        </div>
                      </TableBodyCell>
                    </TableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContainer>

          <div className="row">
            <div className="col-8">
              <CardContainer className="h-225px">
                <div style={{ fontSize: "12px" }}>Top Candidates</div>
                <Table>
                  <TableHead>
                    <TableHeadRow>
                      <TableHeadCell>Candidate</TableHeadCell>
                      <TableHeadCell>Job Role</TableHeadCell>
                      <TableHeadCell>Past Experience</TableHeadCell>
                    </TableHeadRow>
                  </TableHead>
                  <TableBody>
                    {topCandidates.map((listItem) => {
                      return (
                        <TableBodyRow key={listItem.guid}>
                          <TableBodyCell>
                            <div
                              className="symbol symbol-25px symbol-circle"
                              data-bs-toggle="tooltip"
                            >
                              <img alt="Pic" src={listItem.imageUrl} />
                            </div>{" "}
                            {listItem.candidateName}
                          </TableBodyCell>
                          <TableBodyCell>{listItem.jobRole}</TableBodyCell>
                          <TableBodyCell>
                            {listItem.pastExperience}
                          </TableBodyCell>
                        </TableBodyRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContainer>
            </div>
            <div className="col-4">
              <CardContainer className="h-225px">
                <div style={{ fontSize: "12px" }}>
                  Time to fill vs time to here
                </div>
                <div className=" h-150px overflow-auto">{/* <Test /> */}</div>
              </CardContainer>
            </div>
          </div>
          <CardContainer>
            <div className="d-flex gap-2">
              <FilledButtons label="Button" /> <br />
              <FilledButtons label="Button" iconName={"plus-square"} /> <br />
              <div>
                {" "}
                <FilledButtons label="Button" padding="p-0" />
              </div>
              <br />
              <OutlineButton label="Button" />
              <br />
              <div>
                {" "}
                <OutlineButton label="Button" padding="p-0" />
              </div>
              <br />
              <OutlineButton label="Button" iconName={"plus"} /> <br />
              <CancelOutlineButton label="Button" iconName={"plus"} />
            </div>
          </CardContainer>
        </div>{" "}
        <div className="col-4">
          <CardContainer>
            <div style={{ height: "300px" }}>
              <div
                style={{ fontSize: "12px" }}
                className="mb-4 text-capitalize"
              >
                Up Coming Interviews
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="symbol symbol-35px symbol-circle"
                    data-bs-toggle="tooltip"
                  >
                    <img
                      alt="Pic"
                      src={"https://unavatar.io/github/nolannordlund"}
                    />
                  </div>{" "}
                  <div>
                    Mehul Kurkute
                    <div className="text-muted" style={{ fontSize: "10px" }}>
                      React Developer
                    </div>
                  </div>
                </div>
                <div>25 OCT</div>
              </div>
              <div className="separator my-4"></div>
            </div>
          </CardContainer>
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
