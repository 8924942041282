import React from "react";
import { useTranslation } from "react-i18next";
import Error404 from "assets/images/404.png";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="m-auto d-flex flex-column ">
      <img src={Error404} alt="error-404" className="w-400px" />

      <div className="m-auto">
        <button
          className="rounded-pill"
          onClick={() => {
            navigate("/");
          }}
        >
          <span className="text-capitalize"> {t("RETURN.HOME")}</span>
        </button>
      </div>
    </div>
  );
};

export default NotFound;
