import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useTranslation } from "react-i18next";
import { sidebarMenuItems } from "constant/dashboardConstant";

const SidebarMenuMain: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {sidebarMenuItems.map((item, index) => {
        if (item.subItems) {
          return (
            <SidebarMenuItemWithSub
              key={item.to}
              to={item.to}
              title={t(item.titleKey)}
              icon={item.icon}
              fontIcon={item.fontIcon}
            >
              {item.subItems.map((subItem, subIndex) => (
                <SidebarMenuItem
                  key={subIndex}
                  to={subItem.to}
                  title={t(subItem.titleKey)}
                  hasBullet={subItem.hasBullet}
                />
              ))}
            </SidebarMenuItemWithSub>
          );
        }

        return (
          <SidebarMenuItem
            key={index}
            to={item.to}
            icon={item.icon}
            title={t(item.titleKey)}
            fontIcon={item.fontIcon}
          />
        );
      })}
    </>
  );
};

export { SidebarMenuMain };
