import InputTextField from "components/form/InputTextField";
import React from "react";
import { useFormik } from "formik";
const CreateCandidate = () => {
  const initialValues = {
    clientName: "",
    industryName: "",
    location: "",
    contactPersonName: "",
    contactPersonNumber: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {},
  });
  return (
    <div>
      <h1>Create Candidates</h1>
      <InputTextField
        name="clientName"
        value={formik.values.clientName}
        onChange={formik.handleChange}
      />
      <InputTextField
        name="industryName"
        value={formik.values.industryName}
        onChange={formik.handleChange}
      />
      <InputTextField
        name="location"
        value={formik.values.location}
        onChange={formik.handleChange}
      />
      <InputTextField
        name="contactPersonName"
        value={formik.values.contactPersonName}
        onChange={formik.handleChange}
      />
      <InputTextField
        name="contactPersonNumber"
        value={formik.values.contactPersonNumber}
        onChange={formik.handleChange}
      />
    </div>
  );
};

export default CreateCandidate;
