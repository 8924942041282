import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import MenuOpenButton from "components/button/MenuOpenButton";
import ModalOpenButton from "components/button/ModalOpenButton";
import CardContainer from "components/cards/CardContainer";
import ModalContainer from "components/modal/ModalContainer";
import { jobList } from "dummy-json/jobs";
import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";

import HeaderGrid from "components/cards/card-grid/HeaderGrid";
import CreateJob from "./CreateJob";
import { useNavigate } from "react-router-dom";
import RightCardHeaderContainer from "components/cards/card-grid/RightCardHeaderContainer";

const Index = () => {
  const [modalToggle, setModalToggle] = useState(false);
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <CardContainer>
        <HeaderGrid>
          <div>
            <ModalOpenButton
              onClick={() => setModalToggle(true)}
              label={"Create Jobs"}
              iconName="plus-square"
            />
          </div>
          <RightCardHeaderContainer>
            <MenuOpenButton label="Filter" iconName="setting-4" />
            <MenuOpenButton label="More" iconName="dots-horizontal" />
          </RightCardHeaderContainer>
        </HeaderGrid>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Job Role</TableHeadCell>
              <TableHeadCell>Client</TableHeadCell>
              <TableHeadCell>Job Location</TableHeadCell>
              <TableHeadCell>
                Package Range{" "}
                <div className="text-muted m-0 p-0 fw-normal fs-12px">
                  (in Ruppee-LPA)
                </div>
              </TableHeadCell>
              <TableHeadCell>Is negotiable?</TableHeadCell>
              <TableHeadCell>Count</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <TableHeadCell>Date</TableHeadCell>
            </TableHeadRow>
          </TableHead>

          <TableBody>
            {jobList.map((listItem) => {
              return (
                <TableBodyRow
                  onClick={() => {
                    navigate(`/jobs/${listItem.guid}?tab=candidates`);
                  }}
                  className="cursor-pointer"
                  key={listItem.guid}
                >
                  <TableBodyCell>{listItem.jobRole}</TableBodyCell>
                  <TableBodyCell>
                    <div
                      className="symbol symbol-30px symbol-circle"
                      data-bs-toggle="tooltip"
                    >
                      <img alt="Pic" src={listItem.logoUrl} />
                    </div>
                    {listItem.jobClient}
                  </TableBodyCell>
                  <TableBodyCell>{listItem.jobLocation}</TableBodyCell>
                  <TableBodyCell>{listItem.packageRange}</TableBodyCell>
                  <TableBodyCell>{listItem.isNegotiable}</TableBodyCell>
                  <TableBodyCell>{listItem.count}</TableBodyCell>
                  <TableBodyCell>
                    <span className="bg-gray-300 rounded-pill p-2 py-1">
                      <GoDotFill className="text-success" /> {listItem.status}
                    </span>
                  </TableBodyCell>
                  <TableBodyCell>{listItem.createdDate}</TableBodyCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContainer>
      <ModalContainer
        modalToggle={modalToggle}
        setModalToggle={setModalToggle}
        title="Create Client"
      >
        {modalToggle && <CreateJob />}
      </ModalContainer>
    </React.Fragment>
  );
};

export default Index;
