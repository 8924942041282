import CardContainer from "components/cards/CardContainer";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { jobList } from "dummy-json/jobs";

import clsx from "clsx";
import CandidatesRound from "./job-profile-pages/CandidatesRound";
const NavigatorMenuItem = ({
  label,
  tabs,
}: {
  label: string;
  tabs?: string;
}) => {
  const { guid } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Link className="text-decoration-none" to={`/jobs/${guid}?tab=${tabs}`}>
      <div
        className={clsx(
          "mx-4 fs-4 py-4 cursor-pointer ",
          searchParams.get("tab") === tabs
            ? "border-2  border-bottom border-primary text-primary"
            : "text-dark"
        )}
      >
        {label}
      </div>
    </Link>
  );
};
const JobProfile = () => {
  const { guid } = useParams();
  const [searchParams] = useSearchParams();

  const [jobDetails, setJobDetails] = useState({
    guid: "",
    jobRole: "",
    jobClient: "",
    jobLocation: "",
    packageRange: "",
    campaignStartDate: "",
    campaignEndDate: "",
    createdDate: "",
    isNegotiable: "",
    count: "",
    status: "",
    totalVacancy: 0,
    jobDescription: "",
    lastUpdatedBy: "",
    createdBy: "",
    lastUpdatedTime: "",
    logoUrl: "",
  });

  useEffect(() => {
    const jobData = jobList.find((item) => guid === item.guid);
    if (jobData) {
      setJobDetails(jobData);
    } else {
      console.log("Job Not Found");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CardContainer className="pb-1">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="fs-1">{jobDetails.jobRole}</div>
        </div>
        <div className="w-100 border-bottom border-gray-400">
          <div className="d-flex gap-4 ">
            <NavigatorMenuItem label="Candidates" tabs="candidates" />
            <NavigatorMenuItem label="Summary" tabs="summary" />
            <NavigatorMenuItem label="Activities" tabs="activities" />
            <NavigatorMenuItem label="Teams" tabs="teams" />
            <NavigatorMenuItem label="Attachments" tabs="attachments" />
            <NavigatorMenuItem label="Sourcing" tabs="sourcing" />
            <NavigatorMenuItem label="Reports" tabs="reports" />
          </div>
        </div>{" "}
      </CardContainer>
      <br />
      {searchParams.get("tab") === "candidates" && <CandidatesRound />}
    </>
  );
};

export default JobProfile;
