import React, { Dispatch, FC, SetStateAction } from "react";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";

type Props = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const FormTextEditor: FC<Props> = ({ value, setValue }) => {
  return (
    <>
      <EditorToolbar />
      <QuillEditor
        theme="snow"
        value={value}
        formats={formats}
        modules={modules}
        onChange={setValue}
      />
    </>
  );
};

export default FormTextEditor;
