/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { KTIcon } from "helpers";
import { MenuComponent } from "assets/ts/components";
import { Link } from "react-router-dom";
import { useLayout } from "layout/core";
import { sidebarMenuItems } from "constant/dashboardConstant";

const AsideTabs: FC = () => {
  const { link, setLink } = useLayout();
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);
  return (
    <div
      className="hover-scroll-y mb-10"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-wrappers="#kt_aside_nav"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-offset="0px"
      style={{ marginLeft: "2px" }}
    >
      <ul className="nav flex-column" id="kt_aside_nav_tabs">
        {sidebarMenuItems.map((item) => {
          if (item.subItems) {
            return (
              <li key={item.id} className={`my-1`}>
                <div
                  className="d-flex justify-content-center"
                  data-toggle="tooltip"
                  data-theme="white"
                  title={item.titleKey}
                >
                  <div
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="right-start"
                  >
                    <a
                      className={clsx(
                        "nav-link btn btn-icon btn-active-color-primary btn-color-dark btn-active-light n-menu-icon m-0",
                        {
                          active: item.subItems.some(
                            (check) => check.to === link
                          ),
                        }
                      )}
                    >
                      <span>
                        <KTIcon
                          iconName={item.icon}
                          iconType="outline"
                          className="fs-2x"
                        />
                      </span>
                    </a>{" "}
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                      data-kt-menu="true"
                    >
                      <div className="separator "></div>
                      {item.subItems.map((subItem, subIndex) => (
                        <React.Fragment key={subIndex}>
                          <div
                            className="menu-item"
                            onClick={() => {
                              setLink(subItem.to);
                            }}
                          >
                            <Link to={subItem.to} className="menu-link">
                              {subItem.titleKey}
                            </Link>
                          </div>
                          <div className="separator "></div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </li>
            );
          }
          return (
            <li key={item.id} className={`my-1  `}>
              <div
                className="d-flex justify-content-center"
                data-toggle="tooltip"
                data-theme="white"
                title={item.titleKey}
              >
                <Link to={item.to}>
                  <span
                    className={clsx(
                      "nav-link btn btn-icon btn-active-color-primary btn-color-dark btn-active-light n-menu-icon m-0",
                      { active: item.to === link }
                    )}
                    onClick={() => setLink(item.to)}
                  >
                    <span>
                      <KTIcon
                        iconName={item.icon}
                        iconType="outline"
                        className="fs-2x"
                      />
                    </span>
                  </span>{" "}
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { AsideTabs };
