import { WithChildren } from "helpers";
import React, { FC } from "react";
type Props = {
  onClick?: any;
  className?: string;
};
const TableBodyRow: FC<WithChildren & Props> = ({ children, ...rest }) => {
  return <tr {...rest}>{children}</tr>;
};

export default TableBodyRow;
