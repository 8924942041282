import { KTIcon } from "helpers";
import React, { FC } from "react";
type Props = {
  label: string;
  iconName?: string;
};
const MenuOpenButton: FC<Props> = ({ label, iconName }) => {
  return (
    <button className="btn btn-sm n-btn-outline">
      {iconName && (
        <KTIcon iconType="outline" iconName={iconName} className="fs-3" />
      )}
      {label}
    </button>
  );
};

export default MenuOpenButton;
