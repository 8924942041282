import React, { useState } from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import {
  BoardSections as BoardSectionsType,
  INITIAL_CANDIDATES,
  BOARD_SECTIONS,
} from "dummy-json/candidates";
import { getTaskById } from "helpers/dnd-utils/tasks";
import {
  findBoardSectionContainer,
  initializeBoard,
} from "helpers/dnd-utils/board";
import BoardSection from "./BoardSection";
import CandidateCardForRound from "./CandidateCardForRound";

const BoardSectionList = () => {
  const tasks = INITIAL_CANDIDATES;

  const [boardSections, setBoardSections] = useState<BoardSectionsType>(
    initializeBoard(INITIAL_CANDIDATES)
  );

  const [activeTaskId, setActiveTaskId] = useState<null | string>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    console.log("handleDragStart", { active });
    setActiveTaskId(active.id as string);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    console.log("handleDragOver", { active, over });
    // Find the containers
    const activeContainer = findBoardSectionContainer(
      boardSections,
      active.id as string
    );
    const overContainer = findBoardSectionContainer(
      boardSections,
      over?.id as string
    );

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      const activeIndex = activeItems.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...boardSection,
        [activeContainer]: [
          ...boardSection[activeContainer].filter(
            (item) => item.id !== active.id
          ),
        ],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(
            overIndex,
            boardSection[overContainer].length
          ),
        ],
      };
    });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    console.log("handleDragEnd", { active, over });
    const activeContainer = findBoardSectionContainer(
      boardSections,
      active.id as string
    );
    const overContainer = findBoardSectionContainer(
      boardSections,
      over?.id as string
    );

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = boardSections[activeContainer].findIndex(
      (task) => task.id === active.id
    );
    const overIndex = boardSections[overContainer].findIndex(
      (task) => task.id === over?.id
    );

    if (activeIndex !== overIndex) {
      setBoardSections((boardSection) => ({
        ...boardSection,
        [overContainer]: arrayMove(
          boardSection[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveTaskId(null);
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };

  const candidate = activeTaskId ? getTaskById(tasks, activeTaskId) : null;

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: "500px",
            overflow: "scroll",
          }}
        >
          <table>
            <thead>
              <tr>
                {Object.keys(boardSections).map((boardSectionKey) => (
                  <td
                    key={boardSectionKey}
                    className="border border-1 border-gray-400 bg-white p-2 mb-10"
                  >
                    {BOARD_SECTIONS[boardSectionKey]}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="h-25px"></tr>
              <tr>
                {Object.keys(boardSections).map((boardSectionKey) => (
                  <td
                    key={boardSectionKey}
                    style={{
                      minWidth: "245px",
                    }}
                    className="border-right-1 border-gray-400"
                  >
                    <BoardSection
                      id={boardSectionKey}
                      title={boardSectionKey}
                      tasks={boardSections[boardSectionKey]}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <DragOverlay dropAnimation={dropAnimation}>
            {candidate ? <CandidateCardForRound candidate={candidate} /> : null}
          </DragOverlay>
        </div>
      </DndContext>
    </div>
  );
};

export default BoardSectionList;
