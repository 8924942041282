import clsx from "clsx";
import { KTIcon } from "../../helpers";
import { HeaderUserMenu } from "./header-menus/HeaderUserMenu";
import { useLayout } from "../../layout/core";
import profilePic from "../../assets/images/Profile.png";
import { ThemeModeType, useThemeMode } from "hooks‬/theme";
const itemClass = "ms-1 ms-lg-3";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const RightHeaderContainer = () => {
  const { mode, updateMode, updateMenuMode } = useThemeMode();

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
  };

  const { config } = useLayout();
  return (
    <div className="app-navbar flex-shrink-0">
      {mode === "light" ? (
        <div
          className={clsx("app-navbar-item ", itemClass)}
          onClick={() => {
            switchMode("dark");
          }}
        >
          <i
            className="ki-outline ki-moon text-primary pointer"
            style={{ fontSize: "22px" }}
          ></i>
        </div>
      ) : (
        <div
          className={clsx("app-navbar-item ", itemClass)}
          onClick={() => {
            switchMode("light");
          }}
        >
          <i
            className="ki-outline ki-sun text-primary pointer"
            style={{ fontSize: "24px" }}
          ></i>
        </div>
      )}

      <div className={clsx("app-navbar-item ", itemClass)}>
        <i
          className="fa-solid fa-bell text-primary mx-5"
          style={{ fontSize: "20px" }}
        ></i>
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img className="rounded-circle" src={profilePic} alt="" />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="n_one_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { RightHeaderContainer };
