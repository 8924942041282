import CardContainer from "components/cards/CardContainer";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { clientList } from "dummy-json/client";
import FilledGrayButton from "components/button/FilledGrayButton";
import DangerFilledButton from "components/button/DangerFilledButton";
import clsx from "clsx";
import ClientsJobs from "./client-profile-pages/ClientsJobs";
const NavigatorMenuItem = ({
  label,
  tabs,
}: {
  label: string;
  tabs?: string;
}) => {
  const navigate = useNavigate();
  const { clientGuid } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <div
      className={clsx(
        "mx-4 fs-4 py-4 cursor-pointer",
        searchParams.get("tab") === tabs &&
          "border-2  border-bottom border-primary text-primary"
      )}
      onClick={() => {
        navigate(`/client/${clientGuid}?tab=${tabs}`);
      }}
      onKeyDown={() => {
        navigate(`/client/${clientGuid}?tab=${tabs}`);
      }}
      role="button"
      tabIndex={0}
    >
      {label}
    </div>
  );
};
const ClientProfile = () => {
  const { clientGuid } = useParams();
  const [searchParams] = useSearchParams();

  const [clientDetails, setClientDetails] = useState({
    guid: "",
    clientName: "",
    numberOfJobPost: 0,
    numberOfHired: 0,
    Industry: "",
    Location: "",
    contactNumber: "",
    createdData: "",
  });

  useEffect(() => {
    const clientData = clientList.find((item) => clientGuid === item.guid);
    if (clientData) {
      setClientDetails(clientData);
    } else {
      console.log("Client Not Found");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CardContainer>
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-3">{clientDetails.clientName}</div>
          <div className="d-flex gap-4">
            <div>
              <FilledGrayButton xs={true} label="Edit Column" />
            </div>
            <div>
              <FilledGrayButton xs={true} label="Export" />
            </div>
            <div>
              <DangerFilledButton xs={true} label="Delete" />
            </div>
          </div>
        </div>
      </CardContainer>

      <div className="w-100 border-bottom border-gray-400">
        <div className="d-flex gap-4">
          <NavigatorMenuItem label="Jobs" tabs="jobs" />
          <NavigatorMenuItem label="Summary" tabs="Summary" />
          <NavigatorMenuItem label="Activities" tabs="Activities" />
          <NavigatorMenuItem label="Teams" tabs="Teams" />
          <NavigatorMenuItem label="Contacts" tabs="Contacts" />
          <NavigatorMenuItem label="Billing" tabs="Billing" />
          <NavigatorMenuItem label="History" tabs="history" />
        </div>
      </div>
      <br />
      {searchParams.get("tab") === "jobs" && (
        <ClientsJobs clientDetails={clientDetails} />
      )}
    </>
  );
};

export default ClientProfile;
