import { MenuComponent } from "assets/ts/components";
import FilledButtons from "components/button/FilledButtons";
import FilledGrayButton from "components/button/FilledGrayButton";
import InputTextField from "components/form/InputTextField";
import SearchableDropdown from "components/form/SearchableDropdown";
import FormTextEditor from "components/text-editor/FormTextEditor";
import { useFormik } from "formik";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { object } from "yup";
const template = [
  {
    guid: "temp12",
    template:
      "<div><h1>Add Description</h1><br/><h2>Job brief</h2><p>   We are seeking a Software Engineer to execute the full lifecycle of the product development, by programming well-designed, efficient, and testable code that meets specifications.</p> <br/><br/><br/><p> Add anything Here</p></div>",
  },
  {
    guid: "temp13",
    template:
      "<div><h3>Add Description </h3><br/><br/><br/><p> Add anything Here</p></div>",
  },
  {
    guid: "temp14",
    template:
      "<div><h3>Add Description </h3><br/><br/><br/><br/> <p> Add anything Here</p></div>",
  },
];

type Props = {
  clientDetails?: any;
};
const CreateJob: FC<Props> = ({ clientDetails }) => {
  const { t } = useTranslation();
  const validationRules = useAuthValidationRules();
  const [templeSelectionScreen, setTempleSelectionScreen] = useState(false);
  const [isSelectionOption, setIsSelectionOption] = useState(false);
  const [otherOptionField, setOtherOptionField] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState({
    label: "Software Engineer",
    value: "temp12",
  });
  const initialValues = {
    positionName: "",
    selectClient: "",
    JobPipeline: "",
    jobLocation: "",
    contractType: "",
    minimumPackage: "",
    maximumPackage: "",
    campaignStartDate: "",
    campaignEndDate: "",
    totalVacancy: "",
    jobDescription: "",
    skillRequired: "",
    aboutCompany: "",
    jobSummary: "",
    rolesResponsibilities: "",
    requiredQualifications: "",
    SalaryBenefits: "",
    modeOfWork: "",
  };
  useEffect(() => {
    if (clientDetails) {
      formik.setFieldValue("selectClient", clientDetails.guid);
    }

    // eslint-disable-next-line
  }, []);
  const formSchema = object().shape({
    positionName: validationRules.positionNameValidationSchema,
  });
  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
    },
  });

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [templeSelectionScreen, isSelectionOption]);
  if (!isSelectionOption && !templeSelectionScreen) {
    return (
      <div className="p-5 modal-body">
        <div className="row">
          <div className="col-6">
            <div
              className="card p-5"
              onClick={() => setIsSelectionOption(true)}
              onKeyDown={() => setIsSelectionOption(true)}
              role="button"
              tabIndex={0}
            >
              <div className="text-center">
                <i
                  className="ki-outline ki-notepad-edit text-primary "
                  style={{ fontSize: "70px" }}
                />
              </div>
              <div className="text-center fs-3 mt-5"> Complete a Form</div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="card p-5"
              onClick={() => {
                setIsSelectionOption(true);
                setTempleSelectionScreen(true);
              }}
              onKeyDown={() => {
                setIsSelectionOption(true);
                setTempleSelectionScreen(true);
              }}
              tabIndex={0}
              role="button"
            >
              <div className="text-center">
                <i
                  className="ki-outline ki-book text-primary "
                  style={{ fontSize: "70px" }}
                />
              </div>
              <div className="text-center fs-3 mt-5"> Use a Template</div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (templeSelectionScreen) {
    return (
      <>
        <div className="p-5 modal-body ">
          <div className="h-50px">
            <div
              className="menu menu-rounded menu-column menu-gray-600 menu-state-bg fw-semibold w-100"
              data-kt-menu="true"
            >
              <div
                className="menu-item"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
              >
                <span
                  className="menu-link py-3"
                  style={{ border: "1px solid gray" }}
                >
                  <span className="menu-icon">
                    <i className="ki-duotone ki-calendar-2 fs-3">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                    </i>
                  </span>
                  <span className="menu-title">
                    {selectedTemplate.label.length > 0
                      ? selectedTemplate.label
                      : "Select a Template"}
                  </span>
                  <span className="menu-arrow" />
                </span>
                <div className="menu-sub menu-sub-dropdown p-3 w-300px">
                  {[
                    {
                      label: "Software Engineer",
                      value: "temp12",
                    },
                    {
                      label: "Civil Engineer",
                      value: "temp13",
                    },
                    {
                      label: "Office Assistant",
                      value: "temp14",
                    },
                  ].map((item) => {
                    return (
                      <div
                        key={item.value}
                        className="menu-item  "
                        onClick={() => setSelectedTemplate(item)}
                        onKeyDown={() => setSelectedTemplate(item)}
                        role="button"
                        tabIndex={0}
                      >
                        <span className="menu-link   px-1 py-3">
                          <span className="menu-bullet me-0">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">{item.label}</span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-0 pt-4 d-flex">
          <div>
            <FilledButtons
              type="button"
              label="Continue"
              onClick={() => {
                const templateValue = template.find(
                  (temItem) => temItem.guid === selectedTemplate.value
                );
                formik.setFieldValue("jobDescription", templateValue?.template);
                setTempleSelectionScreen(false);
                setIsSelectionOption(true);
              }}
            />
          </div>
          <div>
            <FilledGrayButton
              label="Cancel"
              onClick={() => {
                setSelectedTemplate({
                  label: "Software Engineer",
                  value: "temp12",
                });
                setTempleSelectionScreen(false);
                setIsSelectionOption(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="p-5 modal-body">
        <div className=" p-0">
          <InputTextField
            name="positionName"
            placeholder="Enter Position Name"
            label="Position Name"
            labelClass="required"
            value={formik.values.positionName}
            onChange={formik.handleChange}
            error={
              formik.touched.positionName && Boolean(formik.errors.positionName)
            }
            helperText={
              formik.touched.positionName && formik.errors.positionName
            }
          />
          {otherOptionField ? (
            <>
              <SearchableDropdown
                placeholder={t("Select Client")}
                label="Client Name"
                name="selectClient"
                value={formik.values.selectClient}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={[
                  {
                    label: "ABWC",
                    value: "abcd1234efgh5678ijkl9012mnop3456",
                  },
                  {
                    label: "ABD",
                    value: "ce123",
                  },
                  {
                    label: "ACC",
                    value: "acca123",
                  },
                ]}
              />

              <SearchableDropdown
                label="Job Round"
                placeholder="Select Job Round"
                name="JobPipeline"
                value={formik.values.JobPipeline}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={[
                  {
                    label: "ABC",
                    value: "sfe123",
                  },
                  {
                    label: "ABD",
                    value: "ce1e23",
                  },
                  {
                    label: "ACC",
                    value: "aceca123",
                  },
                ]}
              />

              <InputTextField
                label="Job Location"
                placeholder="Enter Job Location"
                name="jobLocation"
                value={formik.values.jobLocation}
                onChange={formik.handleChange}
              />

              <SearchableDropdown
                label="Job Type"
                placeholder="Select Job Type"
                name="contractType"
                value={formik.values.contractType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={[
                  {
                    label: "Full Time",
                    value: "sfe123",
                  },
                  {
                    label: "Part Time",
                    value: "ce1e23s",
                  },
                  {
                    label: "Internship",
                    value: "acdeca123",
                  },
                  {
                    label: "Contract Base",
                    value: "acdeca1222",
                  },
                ]}
              />

              <div className="row">
                <div className="col-6">
                  <InputTextField
                    label="Minimum Package"
                    placeholder="Enter Minimum Package"
                    name="minimumPackage"
                    value={formik.values.minimumPackage}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Maximum Package"
                    placeholder="Enter Maximum Package"
                    name="maximumPackage"
                    value={formik.values.maximumPackage}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <InputTextField
                    label="Campaign Start Date"
                    placeholder="Enter campaign Start Date"
                    name="campaignStartDate"
                    value={formik.values.campaignStartDate}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Campaign End Date"
                    placeholder="campaign Start Date"
                    name="campaignEndDate"
                    value={formik.values.campaignEndDate}
                    onChange={formik.handleChange}
                  />
                </div>{" "}
              </div>

              <div className="row">
                <div className="col-6">
                  <InputTextField
                    placeholder="Total Vacancy"
                    label="Enter Total Vacancy"
                    name="totalVacancy"
                    value={formik.values.totalVacancy}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    name="skillRequired"
                    label="Skill"
                    placeholder="Enter Skill"
                    value={formik.values.skillRequired}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <InputTextField
                label="About Company"
                placeholder="Enter About Company"
                name="aboutCompany"
                value={formik.values.aboutCompany}
                onChange={formik.handleChange}
              />
              <InputTextField
                label="Job Summary"
                placeholder="Enter Job Summary"
                name="jobSummary"
                value={formik.values.jobSummary}
                onChange={formik.handleChange}
              />
              <InputTextField
                label="Roles Responsibilities"
                placeholder="Enter Roles Responsibilities"
                name="rolesResponsibilities"
                value={formik.values.rolesResponsibilities}
                onChange={formik.handleChange}
              />
              <InputTextField
                label="Required Qualifications"
                placeholder="Enter Required Qualifications"
                name="requiredQualifications"
                value={formik.values.requiredQualifications}
                onChange={formik.handleChange}
              />
              <SearchableDropdown
                label="Mode Of Work"
                placeholder="Select Mode Of Work"
                name="modeOfWork"
                value={formik.values.modeOfWork}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={[
                  {
                    label: "Remote",
                    value: "sfe1e23",
                  },
                  {
                    label: "Office Work",
                    value: "ce1ed23s",
                  },
                  {
                    label: "Hybrid",
                    value: "acdehca123",
                  },
                ]}
              />
              <div className="fv-row mb-5">
                <label className={"form-label text-dark fs-6 mb-0 "}>
                  Description
                  <FormTextEditor
                    value={formik.values.jobDescription}
                    setValue={(value) =>
                      formik.setFieldValue("jobDescription", value)
                    }
                  />{" "}
                </label>
              </div>
            </>
          ) : (
            <button
              onClick={() => {
                setOtherOptionField(true);
              }}
              className="pt-4 text-primary text-active-primary"
              style={{
                border: "0",
                background: "none",
              }}
            >
              + Optional Fields
            </button>
          )}
        </div>
      </div>
      <div className="modal-footer p-0 pt-4 d-flex">
        <div>
          <FilledButtons
            label="Submit"
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={formik.submitForm}
          />
        </div>
        <div>
          <FilledGrayButton
            label="Cancel"
            onClick={() => {
              setIsSelectionOption(false);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CreateJob;
