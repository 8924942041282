import { WithChildren } from "helpers";
import React, { Dispatch, FC, SetStateAction, useRef } from "react";
interface Props {
  title: string;
  modalToggle?: boolean;
  setModalToggle?: Dispatch<SetStateAction<boolean>>;
}
const ModalContainer: FC<Props & WithChildren> = ({
  children,
  setModalToggle = () => {},
  modalToggle = false,
  title,
}) => {
  const confirmationModalRef = useRef<HTMLDivElement | null>(null);
  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="modal_popup"
      ref={confirmationModalRef}
    >
      <div className="modal-dialog modal-lg  w-lg-600px  modal-dialog-scrollable">
        <div className="modal-content p-5">
          <div className="modal-header py-4 px-0">
            <h5 className="modal-title">{title}</h5>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalToggle(!modalToggle)}
              onKeyDown={() => setModalToggle(!modalToggle)}
              role="button"
              tabIndex={0}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          {children}

          {/* <div className="modal-footer p-0 pt-4">
            <button
              type="button"
              className="btn btn-light btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-primary btn-sm">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
