import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTIcon, WithChildren } from "../../../helpers";
import { useLayout } from "../../../layout/core";
import { useTranslation } from "react-i18next";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config, setLink } = useLayout();
  const { app } = config;
  const { t } = useTranslation();
  return (
    <div className="menu-item" onClick={() => setLink(to)}>
      <Link
        className={clsx("menu-link without-sub", { active: isActive })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="n-menu-icon">
            {" "}
            <KTIcon
              iconType={"outline"}
              iconName={icon}
              className="fs-2 menu-title"
            />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3 menu-title", fontIcon)}></i>
        )}
        <span className="menu-title">{t(title)}</span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
