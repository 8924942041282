import React from "react";

const UnderConstruction = () => {
  return (
    <div>
      <h1>PAGE</h1>
      <h3>Under Construction</h3>
    </div>
  );
};

export default UnderConstruction;
