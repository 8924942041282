import { WithChildren } from "helpers";
import React, { FC } from "react";

const Table: FC<WithChildren> = ({ children }) => {
  return (
    <div className="table-responsive">
      <table className="table align-middle table-hover table-row-bordered fs-6 gy-3 dataTable no-footer">
        {children}
      </table>
    </div>
  );
};

export default Table;
