import BoardSectionList from "components/candidates-dnd/BoardSectionList";
import React from "react";

const CandidatesRound = () => {
  return (
    <div>
      <BoardSectionList />
    </div>
  );
};

export default CandidatesRound;
