import { KTIcon } from "helpers";
import React, { FC } from "react";
interface Props {
  title: string;
  iconName?: string;
  onClickAction: () => void;
}
const ActionMenuItem: FC<Props> = ({ title, iconName, onClickAction }) => {
  return (
    <div className="menu-item px-3">
      <span
        className="menu-link px-3 pointer"
        onKeyDown={() => onClickAction()}
        onClick={() => onClickAction()}
        role="button"
        tabIndex={0}
      >
        {iconName && <KTIcon iconName={iconName} className="fs-3" />}
        {title}
      </span>
    </div>
  );
};

export default ActionMenuItem;
