interface JobListingType {
  guid: string;
  positionName: string;
  jobClient: string;
  jobLocation: string;
  packageRange: string;
  campaignStartDate: string;
  campaignEndDate: string;
  createdDate: string;
  isNegotiable: boolean;
  count: number;
  status: string;
  totalVacancy: number;
  jobDescription: string;
  lastUpdatedBy: string;
  createdBy: string;
  lastUpdatedTime: string;
}
export const jobListings: JobListingType[] = [
  {
    guid: "1a2b3c4d",
    positionName: "Software Engineer",
    jobClient: "Tech Corp",
    jobLocation: "New York, NY",
    packageRange: "5-8",
    campaignStartDate: "2024-08-01",
    campaignEndDate: "2024-12-31",
    createdDate: "2024-07-01",
    isNegotiable: true,
    count: 5,
    status: "Open",
    totalVacancy: 3,
    jobDescription: "Develop and maintain software applications.",
    lastUpdatedBy: "HR Manager",
    createdBy: "Recruitment Team",
    lastUpdatedTime: "2024-07-25T10:00:00Z",
  },
  {
    guid: "2e3f4g5h",
    positionName: "Marketing Specialist",
    jobClient: "Marketing Hub",
    jobLocation: "San Francisco, CA",
    packageRange: "5-8",
    campaignStartDate: "2024-08-15",
    campaignEndDate: "2024-11-30",
    createdDate: "2024-07-05",
    isNegotiable: false,
    count: 2,
    status: "Open",
    totalVacancy: 1,
    jobDescription: "Create and manage marketing campaigns.",
    lastUpdatedBy: "Marketing Lead",
    createdBy: "HR Team",
    lastUpdatedTime: "2024-07-24T14:30:00Z",
  },
  {
    guid: "3i4j5k6l",
    positionName: "Data Analyst",
    jobClient: "Data Insights",
    jobLocation: "Chicago, IL",
    packageRange: "5-8",
    campaignStartDate: "2024-09-01",
    campaignEndDate: "2024-12-15",
    createdDate: "2024-07-10",
    isNegotiable: true,
    count: 3,
    status: "Closed",
    totalVacancy: 2,
    jobDescription: "Analyze and interpret complex data sets.",
    lastUpdatedBy: "Data Manager",
    createdBy: "Recruitment Team",
    lastUpdatedTime: "2024-07-23T11:45:00Z",
  },
];

interface JobListType {
  guid: string;
  jobRole: string;
  jobClient: string;
  jobLocation: string;
  count: string;
  packageRange: string;
  campaignStartDate: string;
  campaignEndDate: string;
  createdDate: string;
  isNegotiable: string;

  status: string;
  totalVacancy: number;
  jobDescription: string;
  lastUpdatedBy: string;
  createdBy: string;
  lastUpdatedTime: string;
  logoUrl: string;
}

export const jobList: JobListType[] = [
  {
    guid: "9f8e7d6c",
    jobRole: "Data Analyst",
    jobClient: "Data Solutions Inc.",
    jobLocation: "San Francisco, CA",
    packageRange: "5-8",
    campaignStartDate: "2024-07-15",
    campaignEndDate: "2024-11-15",
    createdDate: "2024-07-01",
    isNegotiable: "No",
    count: "8",
    status: "Active",
    totalVacancy: 2,
    jobDescription: "Analyze and interpret complex data sets.",
    lastUpdatedBy: "HR Specialist",
    createdBy: "Recruitment Team",
    lastUpdatedTime: "2024-07-20T14:30:00Z",
    logoUrl: "https://pngimg.com/d/google_PNG19635.png",
  },
  {
    guid: "2g3h4i5j",
    jobRole: "Frontend Developer",
    jobClient: "WebWorks",
    jobLocation: "Austin, TX",
    packageRange: "5-8",
    campaignStartDate: "2024-08-01",
    campaignEndDate: "2024-12-01",
    createdDate: "2024-07-05",
    isNegotiable: "Yes",
    count: "10",
    status: "Active",
    totalVacancy: 3,
    jobDescription: "Build and optimize user interfaces for web applications.",
    lastUpdatedBy: "Recruiter",
    createdBy: "HR Team",
    lastUpdatedTime: "2024-07-25T12:00:00Z",
    logoUrl: "https://pngimg.com/d/google_PNG19635.png",
  },
  {
    guid: "3k4l5m6n",
    jobRole: "Backend Developer",
    jobClient: "ServerSide Inc.",
    jobLocation: "Seattle, WA",
    packageRange: "5-8",
    campaignStartDate: "2024-09-01",
    campaignEndDate: "2024-12-31",
    createdDate: "2024-07-10",
    isNegotiable: "Yes",
    count: "6",
    status: "Active",
    totalVacancy: 4,
    jobDescription: "Develop and maintain server-side logic and databases.",
    lastUpdatedBy: "HR Manager",
    createdBy: "Tech Recruitment Team",
    lastUpdatedTime: "2024-07-28T16:00:00Z",
    logoUrl: "https://pngimg.com/d/google_PNG19635.png",
  },
];
