import FilledButtons from "components/button/FilledButtons";
import FilledGrayButton from "components/button/FilledGrayButton";
import InputTextField from "components/form/InputTextField";
import FormTextEditor from "components/text-editor/FormTextEditor";
import { useFormik } from "formik";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import React, { useState } from "react";
import { object } from "yup";
const CreateClients = () => {
  const [otherOptionField, setOtherOptionField] = useState(false);
  const validationRules = useAuthValidationRules();
  const initialValues = {
    clientName: "",
    industryName: "",
    location: "",
    contactPersonName: "",
    contactPersonNumber: "",
    description: "",
    website: "",
  };
  const formSchema = object().shape({
    clientName: validationRules.clientNameValidationSchema,
  });
  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
    },
  });
  return (
    <>
      <div className="p-5 modal-body">
        <div className=" p-0">
          <InputTextField
            name="clientName"
            placeholder="Enter Client Name"
            label="Client Name"
            labelClass="required"
            value={formik.values.clientName}
            onChange={formik.handleChange}
            error={
              formik.touched.clientName && Boolean(formik.errors.clientName)
            }
            helperText={formik.touched.clientName && formik.errors.clientName}
          />
          {otherOptionField ? (
            <>
              <InputTextField
                label="Industry Name"
                placeholder="Enter Industry Name"
                name="industryName"
                value={formik.values.industryName}
                onChange={formik.handleChange}
              />

              <div className="row">
                <div className="col-6">
                  <InputTextField
                    label="Location"
                    placeholder="Enter Location"
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Website"
                    placeholder="Enter Website"
                    name="website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                  />
                </div>{" "}
              </div>
              <div className="row">
                <div className="col-6">
                  <InputTextField
                    placeholder="Enter Contact Person Name"
                    label="Contact Person Name"
                    name="contactPersonName"
                    value={formik.values.contactPersonName}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    name="contactPersonNumber"
                    label="Contact Person Number"
                    placeholder="Enter Contact Person Number"
                    value={formik.values.contactPersonNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="fv-row mb-5">
                <label className={"form-label text-dark fs-6 mb-0 "}>
                  Description
                  <FormTextEditor
                    value={formik.values.description}
                    setValue={formik.handleChange}
                  />{" "}
                </label>
              </div>
            </>
          ) : (
            <button
              onClick={() => {
                setOtherOptionField(true);
              }}
              className="pt-4 text-primary text-active-primary"
              style={{
                border: "0",
                background: "none",
              }}
            >
              + Optional Fields
            </button>
          )}
        </div>
      </div>

      <div className="modal-footer p-0 pt-4 d-flex">
        <div>
          <FilledButtons
            disabled={formik.isSubmitting || !formik.isValid}
            label="Submit"
            onClick={formik.submitForm}
          />
        </div>
        <div>
          <FilledGrayButton label="Cancel" data-bs-dismiss="modal" />
        </div>
      </div>
    </>
  );
};

export default CreateClients;
