import React from "react";
import { Link } from "react-router-dom";

const BreadcrumbDirective = ({ to, label }: { to?: string; label: string }) => {
  return (
    <React.Fragment>
      {to ? (
        <div className="d-flex align-content-center">
          <Link to={to}>
            <span className="text-muted pointer">{label}</span>
          </Link>
          <i className="ki-duotone ki-right mx-2 mt-1"></i>
        </div>
      ) : (
        <span className="text-primary">{label}</span>
      )}
    </React.Fragment>
  );
};

export default BreadcrumbDirective;
