import { KTIcon } from "helpers";
import React, { FC } from "react";
type Props = {
  label: string;
  iconName?: string;
  onClick?: any;
};
const ModalOpenButton: FC<Props> = ({ label, iconName, ...rest }) => {
  return (
    <button
      className="btn btn-primary btn-sm"
      data-bs-toggle="modal"
      data-bs-target="#modal_popup"
      {...rest}
    >
      {iconName && <KTIcon iconName={iconName} className="fs-3" />} {label}
    </button>
  );
};

export default ModalOpenButton;
