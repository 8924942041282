import { useTranslation } from "react-i18next";
import { string, ref } from "yup";

export function useAuthValidationRules() {
  const { t } = useTranslation();

  const emailValidationSchema = string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      t("messages:ENTER.VALID") + " " + t("EMAIL")
    )
    .max(320, t("messages:MAXIMUM.CHARACTERS") + " " + t("320"))
    .required(t("EMAIL") + " " + t("messages:IS.REQUIRED"));

  const clientNameValidationSchema = string().required(
    t("CLIENT.NAME") + " " + t("messages:IS.REQUIRED")
  );

  const positionNameValidationSchema = string().required(
    t("Position Name") + " " + t("messages:IS.REQUIRED")
  );

  const jobDescriptionValidationSchema = string().required(
    t("Job Name") + " " + t("messages:IS.REQUIRED")
  );
  const skillRequiredValidationSchema = string().required(
    t("Skill") + " " + t("messages:IS.REQUIRED")
  );
  const aboutCompanyValidationSchema = string().required(
    t("About Company") + " " + t("messages:IS.REQUIRED")
  );
  const jobSummaryValidationSchema = string().required(
    t("Job Summary") + " " + t("messages:IS.REQUIRED")
  );
  const rolesResponsibilitiesValidationSchema = string().required(
    t("Name") + " " + t("messages:IS.REQUIRED")
  );
  const requiredQualificationsValidationSchema = string().required(
    t("Name") + " " + t("messages:IS.REQUIRED")
  );
  const SalaryBenefitsValidationSchema = string().required(
    t("Name") + " " + t("messages:IS.REQUIRED")
  );
  const ModeOfWorkValidationSchema = string().required(
    t("Name") + " " + t("messages:IS.REQUIRED")
  );

  const firstNameValidationSchema = string().required(
    t("FIRST.NAME") + " " + t("messages:IS.REQUIRED")
  );

  const lastNameValidationSchema = string().required(
    t("LAST.NAME") + " " + t("messages:IS.REQUIRED")
  );

  const roleNameValidationSchema = string().required(
    t("ROLE.NAME") + " " + t("messages:IS.REQUIRED")
  );

  const cloneRoleGuidValidationSchema = string().required(
    t("messages:SELECT.OPTION")
  );
  const passwordValidationSchema = string()
    .min(8, t("messages:PWD.MINIMUM.CHARACTERS"))
    .max(50, t("messages:PWD.MAXIMUM.CHARACTERS"))
    .required(t("PASSWORD") + " " + t("messages:IS.REQUIRED"));

  const confirmPasswordValidationSchema = string()
    .min(8, t("messages:PWD.MINIMUM.CHARACTERS"))
    .max(50, t("messages:PWD.MAXIMUM.CHARACTERS"))
    .required(t("CONFIRM.PASSWORD") + " " + t("messages:IS.REQUIRED"))
    .oneOf([ref("password")], t("messages:CONFIRM.PASSWORD.MATCH"));

  const nameValidationSchema = string()
    .required(t("Name") + " " + t("messages:IS.REQUIRED"))
    .max(50, t("messages:MAX.CHARACTERS") + " " + t("50"));

  const contactNumberValidationSchema = string()
    .matches(/^\d/, t("messages:CONTACT.NUMBER"))
    .max(15, t("messages:MAX.CHARACTERS") + " " + t("15"))
    .min(10, t("messages:MIN.CHARACTERS") + " " + t("10"))
    .required(t("CONTACT.NUMBER") + " " + t("messages:IS.REQUIRED"));

  const address1ValidationSchema = string()
    .required(t("ADDRESS1") + " " + t("messages:IS.REQUIRED"))
    .max(300, t("messages:MAXIMUM.CHARACTERS") + " " + t("300"));

  const address2ValidationSchema = string().max(
    300,
    t("messages:MAXIMUM.CHARACTERS") + " " + t("300")
  );

  const pinCodeValidationSchema = string()
    .required(t("PINCODE") + " " + t("messages:IS.REQUIRED"))
    .max(12, t("messages:MAXIMUM.CHARACTERS") + " " + t("12"));

  const websiteUrlValidationSchema = string().max(
    1024,
    t("messages:MAXIMUM.CHARACTERS") + " " + t("1024")
  );

  return {
    clientNameValidationSchema,
    positionNameValidationSchema,
    jobDescriptionValidationSchema,
    skillRequiredValidationSchema,
    aboutCompanyValidationSchema,
    jobSummaryValidationSchema,
    rolesResponsibilitiesValidationSchema,
    requiredQualificationsValidationSchema,
    SalaryBenefitsValidationSchema,
    ModeOfWorkValidationSchema,
    emailValidationSchema,
    passwordValidationSchema,
    firstNameValidationSchema,
    lastNameValidationSchema,
    confirmPasswordValidationSchema,
    nameValidationSchema,
    contactNumberValidationSchema,
    address1ValidationSchema,
    address2ValidationSchema,
    pinCodeValidationSchema,
    websiteUrlValidationSchema,
    roleNameValidationSchema,
    cloneRoleGuidValidationSchema,
  };
}
