export type ClientListType = {
  guid: string;
  clientName: string;
  numberOfJobPost: number;
  numberOfHired: number;
  Industry: string;
  Location: string;
  contactNumber: string;
  createdData: string;
  contactPerson: string;
};
export const clientList: ClientListType[] = [
  {
    guid: "abcd1234efgh5678ijkl9012mnop3456",
    clientName: "John Doe",
    numberOfJobPost: 12,
    numberOfHired: 10,
    Industry: "Technology",
    Location: "San Francisco, USA",
    contactPerson: "Mehul Kurkute",
    contactNumber: "1234567890",
    createdData: "10-01-2024",
  },
  {
    guid: "qrst6789uvwx1234yzab5678cdef9012",
    clientName: "Jane Smith",
    numberOfJobPost: 30,
    numberOfHired: 25,
    Industry: "Finance",
    Location: "New York, USA",

    contactPerson: "Mehul Kurkute",
    contactNumber: "0987654321",
    createdData: "15-02-2024",
  },
  {
    guid: "ijkl9012mnop3456abcd1234efgh5678",
    clientName: "Alex Johnson",
    numberOfJobPost: 18,
    numberOfHired: 15,
    Industry: "Healthcare",
    Location: "London, UK",

    contactPerson: "Mehul Kurkute",
    contactNumber: "1122334455",
    createdData: "25-03-2024",
  },
  {
    guid: "uvwx1234yzab5678cdef9012qrst6789",
    clientName: "Emily Davis",
    numberOfJobPost: 22,
    numberOfHired: 19,
    Industry: "Education",
    Location: "Sydney, Australia",
    contactNumber: "5566778899",

    contactPerson: "Mehul Kurkute",
    createdData: "30-04-2024",
  },
  {
    guid: "mnop3456abcd1234efgh5678ijkl9012",
    clientName: "Michael Brown",
    numberOfJobPost: 16,
    numberOfHired: 14,
    Industry: "Retail",
    Location: "Toronto, Canada",
    contactNumber: "2233445566",

    contactPerson: "Mehul Kurkute",
    createdData: "05-05-2024",
  },
];
