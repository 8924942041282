import clsx from "clsx";
import { KTIcon } from "helpers";
import React, { FC } from "react";
interface Props {
  loading?: boolean;
  label: string;
  type?: "submit" | "button" | "reset";
  styleClass?: string;
  disabled?: boolean;
  iconName?: string;
  padding?: string;
}
const OutlineButton: FC<Props> = ({
  label,
  styleClass = "",
  type = "button",
  iconName,
  padding,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        "n-primary-outline-btn " + styleClass,
        padding ? "px-2 py-1" : "p-2"
      )}
      type={type}
      {...rest}
    >
      <span className="d-flex items-align-center justify-content-center gap-1">
        {iconName && (
          <KTIcon iconName={iconName} className="fs-3 n-outline-btn-icon" />
        )}
        {label}
      </span>
    </button>
  );
};
export default OutlineButton;
