import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CandidatesType } from "dummy-json/candidates";
import SortableTaskItem from "./SortableTaskItem";
import CandidateCardForRound from "./CandidateCardForRound";

type BoardSectionProps = {
  id: string;
  title: string;
  tasks: CandidatesType[];
};

const BoardSection = ({ id, title, tasks }: BoardSectionProps) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={tasks}
      strategy={verticalListSortingStrategy}
    >
      <div
        style={{
          minHeight: "600px",
          padding: "20px",
        }}
        ref={setNodeRef}
      >
        {tasks.map((candidate) => (
          <div key={candidate.id} style={{ marginBottom: "20px" }}>
            <SortableTaskItem id={candidate.id}>
              <CandidateCardForRound candidate={candidate} />
            </SortableTaskItem>
          </div>
        ))}
      </div>
    </SortableContext>
  );
};

export default BoardSection;
