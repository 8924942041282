import ModalContainer from "components/modal/ModalContainer";
import React from "react";
import CreateCandidate from "./CreateCandidate";

const index = () => {
  return (
    <div>
      <div
        className="menu-item px-3"
        data-bs-toggle="modal"
        data-bs-target="#modal_popup"
      >
        create candidates
      </div>
      <ModalContainer title="Create Candidates">
        <CreateCandidate />
      </ModalContainer>
    </div>
  );
};

export default index;
