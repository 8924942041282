export const BOARD_SECTIONS: any = {
  newCandidates: "New Candidates",
  interested: "Interested",
  shortlisted: "Shortlisted",
  offered: "Offered",
  hired: "hired",
};

export type CandidatesType = {
  id: string;
  title: string;
  description: string;
  status: string;
};
export type BoardSections = {
  [name: string]: CandidatesType[];
};
export const INITIAL_CANDIDATES: CandidatesType[] = [
  {
    id: "ghjbjhsdhshddfd3hjhdhfhdf-d-23238djsdds",
    title: "Mehul Kurkute",
    description: "Desc 2",
    status: "newCandidates",
  },
  {
    id: "ghjbjhsdhshhjheerehdf-d-23238djsdffddds",
    title: "Hard Druv",
    description: "Desc 3",
    status: "newCandidates",
  },
  {
    id: "ghjbjhsdhshhjhdd42dddfhdf-d-23238djsdds",
    title: "Viraj Dhimmar",
    description: "Desc 4",
    status: "hired",
  },
];
