import React, { FC, ReactNode } from "react";
interface PropsType {
  children: ReactNode;
  className?: string;
}
const CardContainer: FC<PropsType> = ({ children, className }) => {
  return <div className={"card p-5  my-2 " + className}>{children}</div>;
};

export default CardContainer;
