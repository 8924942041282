import clsx from "clsx";
import { CandidatesType } from "dummy-json/candidates";
import { HiDotsVertical } from "react-icons/hi";
type CandidateProps = {
  candidate: CandidatesType;
};
const stopPropagation = (e: any) => {
  e.stopPropagation();
};
const CandidateCardForRound = ({ candidate }: CandidateProps) => {
  const candidateDetails = {
    imageUrl: "",
    guid: "mk63882002892nsjndn3jj",
    name: "Mehul Kurkute",
    jobRole: "React Developer",
    company: "Niyantras",
  };
  return (
    <div className="card p-2 w-200px cursorDown">
      <div>
        <div className="my-4 d-flex justify-content-between">
          <div>
            <div
              className="symbol symbol-25px symbol-circle"
              data-bs-toggle="tooltip"
            >
              {candidateDetails.imageUrl ? (
                <img alt="Pic" src={candidateDetails.imageUrl} />
              ) : (
                <span
                  className={clsx(
                    "symbol-label fw-bold mx-2 cursor-pointer",
                    "bg-danger",
                    "text-inverse-danger"
                  )}
                  onPointerDown={stopPropagation}
                >
                  {candidate.title.charAt(0)}
                </span>
              )}
            </div>{" "}
            <span className="cursor-pointer" onPointerDown={stopPropagation}>
              {candidate.title}
            </span>
          </div>
          <HiDotsVertical className="fs-2" />
        </div>
        <div className="m-2">
          <div>{candidateDetails.jobRole}</div>
          <div>@{candidateDetails.company}</div>
        </div>
      </div>
    </div>
  );
};
export default CandidateCardForRound;
